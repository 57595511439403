import React from 'react';
import { Link } from 'react-router-dom';

const Failed = () => {
  return (
    <>
      <h5>Passcode login</h5>
      <p>That email and passcode combination failed.</p>
      <p>
        <Link to="/email">Try again</Link>
      </p>
    </>
  );
};

export default Failed;
