import React, { useState } from 'react';
import Flex from 'components/common/Flex';
import { Button, Form } from 'react-bootstrap';

const Passcode = () => {
  // State
  const [formData, setFormData] = useState({
    passcode: '',
    remember: false
  });

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    // go to passcode
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      <Flex justifyContent="between" alignItems="center" className="mb-2">
        <h5>Passcode login</h5>
      </Flex>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          {<Form.Label>Step 2 - enter passcode from email</Form.Label>}
          <Form.Control
            placeholder={'Passcode'}
            value={formData.passcode}
            name="passcode"
            onChange={handleFieldChange}
            type="passcode"
          />
        </Form.Group>

        <Form.Group>
          <Button
            type="submit"
            color="primary"
            className="mt-3 w-100"
            disabled={!formData.passcode}
          >
            Login with passcode
          </Button>
        </Form.Group>
      </Form>
    </>
  );
};

export default Passcode;
