import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import is from 'is_js';
import ErrorLayout from './ErrorLayout';
import Starter from 'components/pages/Starter';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import AuthSimpleLayout from './AuthSimpleLayout';
import Email from 'si/auth/Email';
import Passcode from 'si/auth/Passcode';
import Failed from 'si/auth/Failed';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  return (
    <>
      <Routes>
        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>

        <Route element={<AuthSimpleLayout />}>
          <Route path="/email" element={<Email />} />
          <Route path="/passcode" element={<Passcode />} />
          <Route path="/failed" element={<Failed />} />
        </Route>

        {/* //--- MainLayout Starts  */}

        <Route path="/" element={<Starter />} />
        <Route path="/starter" element={<Starter />} />

        {/* //--- MainLayout end  */}

        {/* <Navigate to="/errors/404" /> */}
        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>
    </>
  );
};

export default Layout;
